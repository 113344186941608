import {getVendorList} from '../../api/vendorApi'
import {getProductsCategories , getProductDimensionOptions} from '../../api/productsApi'
import {getFilterThemes , getFilterRoomTypes} from '../../api/filtersApi'


const WorkFlowPage = {
    state:()=>({
        vendorList:[],
        productCategories:[],
        filterThemes:[],
        dimensionOptions:[],
        filterRoomTypes:[],
    }),

    mutations:{
        setVendorList(state, payload){
            state.vendorList = payload
        },
        setProductCategories(state, payload){
            state.productCategories = payload
        },
        setFilterThemes(state,payload){
            state.filterThemes = payload
        },
        setDimensionOptions(state,payload){
            state.dimensionOptions = payload
        },
        setFilterRoomTypes(state,payload){
            state.filterRoomTypes = payload
        },

    },

    getters:{},

    actions:{
        fetchVendorList({commit}){
            return getVendorList()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setVendorList', payload)
                        return payload
                    }
                })
        },
        fetchProductCategories({commit}){
            return getProductsCategories()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setProductCategories', payload)
                        return payload
                    }
                })
        },
        fetchThemeFilters({commit}){
            return getFilterThemes()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setFilterThemes', payload)
                        return payload
                    }
                })
        },
        fetchDimensionOptions({commit}){
            return getProductDimensionOptions()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setDimensionOptions', payload)
                        return payload
                    }
                })
        },
        fetchRoomTypeFilters({commit}){
            return getFilterRoomTypes()
                .then(payload => {
                    if (payload.status === 404) {
                        console.log('fetching failed')
                    } else {
                        console.log(payload)
                        commit('setFilterRoomTypes', payload)
                        return payload
                    }
                })
        },

    },

}

export default WorkFlowPage