import Api from 'utils/Api'

export function getVendorList(){
    return Api
        .get('VendorManagement/vendors/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}