<template>
<div>
    <div class="container">
        <div class="button-row">
            <NewButton @click.native="changeDisplay(0)" v-if="display_code!=0" buttonType="normal" buttonText="Go Back"/>
        </div>
        <div v-if="display_code==0">
            <div class="entity-row">
                <h2>Products</h2><NewButton @click.native="changeDisplay(1)" buttonText="Add Products" buttonType="normal" /><NewButton @click.native="changeDisplay(2)" buttonText="View Products" buttonType="normal" />
            </div>
            <div class="entity-row">
                <h2>Scene</h2><NewButton @click.native="changeDisplay(3)" buttonText="Add Scenes" buttonType="normal" /><NewButton @click.native="changeDisplay(4)" buttonText="View Scenes" buttonType="normal" />
            </div>
            <div class="entity-row">
                <h2>Collections of Products</h2><NewButton @click.native="changeDisplay(5)" buttonText="Add Product Collections" buttonType="normal" /><NewButton @click.native="changeDisplay(6)" buttonText="View Product Collections" buttonType="normal" />
            </div>
            <div class="entity-row">
                <h2>Collections of Scenes</h2><NewButton @click.native="changeDisplay(7)" buttonText="Add Scene Collections" buttonType="normal" /><NewButton @click.native="changeDisplay(8)" buttonText="View Scene Collections" buttonType="normal" />
            </div>
            <div class="entity-row">
                <h2>BOQ List</h2><NewButton @click.native="changeDisplay(9)" buttonText="Add BOQ" buttonType="normal" /><NewButton @click.native="changeDisplay(10)" buttonText="View BOQ" buttonType="normal" />
            </div>
        </div>

        <ProductForm v-if="display_code==1"
        :vendor_list="vendorList"
        :category_list="productCategories"
        :dimension_options="dimensionOptions"
        />
        <ProductList v-if="display_code==2"
        :vendor_list="vendorList"
        :category_list="productCategories"
         />
         <SceneForm v-if="display_code==3"
         />
        <ProductCollectionList v-if="display_code==6"
         :theme_data="filterThemes.theme_data" />
        <ProductCollectionForm v-if="display_code==5"
         :theme_data="filterThemes.theme_data"
          />
        <SceneCollectionList v-if="display_code==8"
        :theme_data="filterThemes.theme_data"
        :room_type_data="filterRoomTypes.filter_data" />
        <SceneCollectionForm v-if="display_code==7"
        :theme_data="filterThemes.theme_data"
        :room_type_data="filterRoomTypes.filter_data" />
        <BOQForm v-if="display_code==9"
        :theme_data="filterThemes.theme_data" />
    </div>
</div>
    
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import WorkFlowPageModule from 'store/modules/WorkFlowPage'

import ProductForm from 'components/ProductForm'
import NewButton from 'components/NewButton'
import ProductList from 'components/ProductList'
import ProductCollectionList from 'components/ProductCollectionList'
import ProductCollectionForm from 'components/ProductCollectionForm'
import SceneCollectionList from 'components/SceneCollectionList'
import SceneCollectionForm from 'components/SceneCollectionForm'
import BOQForm from 'components/BOQForm'
import SceneForm from 'components/SceneForm'
export default {
    name:"WorkFlowPage",
    components:{
        ProductForm,
        NewButton,
        ProductList,
        ProductCollectionList,
        ProductCollectionForm,
        SceneCollectionList,
        SceneCollectionForm,
        BOQForm,
        SceneForm,
    },
    data(){
        return{
            display_code :0,
        }

    },
    mixins:[RegisterStoreModule],
    computed:{
        ...mapState({
            vendorList:state => state.WorkFlowPageModule.vendorList,
            productCategories: state=> state.WorkFlowPageModule.productCategories,
            filterThemes: state=>state.WorkFlowPageModule.filterThemes,
            dimensionOptions: state=>state.WorkFlowPageModule.dimensionOptions,
            filterRoomTypes: state=>state.WorkFlowPageModule.filterRoomTypes,

        }),
    },
    created(){
        this.registerStoreModule('WorkFlowPageModule',WorkFlowPageModule)
        this.fetchVendorList()
        this.fetchProductCategories()
        this.fetchThemeFilters()
        this.fetchDimensionOptions()
        this.fetchRoomTypeFilters()
    },
    destroyed(){
        this.$store.unregisterModule('WorkFlowPageModule')
    },
    methods:{
        ...mapActions({
            fetchVendorList:'fetchVendorList',
            fetchProductCategories:'fetchProductCategories',
            fetchThemeFilters:'fetchThemeFilters',
            fetchDimensionOptions:'fetchDimensionOptions',
            fetchRoomTypeFilters:'fetchRoomTypeFilters',
        }),
        changeDisplay(v){
            this.display_code = v;
        }
    }
}
</script>

<style scoped>
.container{
    padding-left: 100px;
    padding-right: 100px;
}
.entity-row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top:15px;
}
.button-row{
    justify-content: right;
}

</style>