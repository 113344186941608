<template>
    <div class="scene-form" >
        <div>
            <h2><u>Basic Information</u></h2>
            <div>
                <h3><u>Choose Room</u></h3>
                <form @submit="dummySubmit()">
                    <div>
                            <h1 class="filter-title">Select Developer</h1>
                            <select @change="getNewProjects()"  v-model="developer_id">
                                <option value=null disabled selected>Developers</option>
                                <option  v-for="dev in developer_list" :key="dev.id" :value="dev.id" >
                                    {{dev.name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <h1 class="filter-title">Select Project</h1>
                            <select @change='getNewPhase()'  v-model="project_id">
                                <option value=null disabled selected>Projects</option>
                                <option  v-for="project in projects_list" :key="project.id" :value="project.id" >
                                    {{project.name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <h1 class="filter-title">Select Phase</h1>
                            <select @change='getNewGisou()'  v-model="phase_id">
                                <option value=null disabled selected>Phases</option>
                                <option  v-for="phase in phase_list" :key="phase.id" :value="phase.id" >
                                    {{phase.name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <h1 class="filter-title">Select GI Set of Units</h1>
                            <select @change='getNewFloorplan()'  v-model="gisou_id">
                                <option value=null disabled selected>GI Sets of Units</option>
                                <option  v-for="gisou in gisou_list" :key="gisou.id" :value="gisou.id" >
                                    {{gisou.building_name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <h1 class="filter-title">Select FloorPlan</h1>
                            <select @change='getNewRoomplans()'  v-model="save_form.fp_id">
                                <option value=null disabled selected>Floor Plans</option>
                                <option  v-for="fp in fp_list" :key="fp.id" :value="fp.id" >
                                    {{fp.name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <h1 class="filter-title">Select RoomPlan</h1>
                            <select  v-model="save_form.rp_id">
                                <option value=null disabled selected>Room Plans</option>
                                <option  v-for="rp in rp_list" :key="rp.id" :value="rp.id" >
                                    {{rp.room_type}}
                                </option>
                            </select>
                        </div>
                </form>
                <h3><u>Select MoodBoard</u></h3>
                <form>
                    <div>
                        <h1 class="filter-title">Select MoodBoard</h1>
                        <select  v-model="save_form.moodboard_id">
                            <option value=null disabled selected>MoodBoards</option>
                            <option :value="mood.id" v-for="mood in moodboard_list" :key="mood.id" >
                                {{mood.name}}
                            </option>
                        </select>
                    </div>
                </form>
                <h3><u>Select Artist</u></h3>
                <form>
                    <div>
                        <h1 class="filter-title">Select Artist</h1>
                        <select  v-model="save_form.artist_id">
                            <option value=null disabled selected>Artists</option>
                            <option :value="artist.id" v-for="artist in artist_list" :key="artist.id" >
                                {{artist.name}}
                            </option>
                        </select>
                    </div>
                </form>
                <h3><u>Upload Images</u></h3>
                <form  @submit="dummySubmit()">
                    <div>
                        <div>
                            <label for="image_file"><h3>Upload Top Image</h3></label>
                            <input
                                type="file"
                                class="image-input-field"
                                name="image_file"
                                @change="selectTop()"
                                ref="top_image"
                            />
                            <img v-if="top_img_url" :src="top_img_url" height="100" width="100" />
                        </div>
                    </div>
                    <div>
                        <div>
                            <label for="image_file"><h3>Upload Corner Image One</h3></label>
                            <input
                                type="file"
                                class="image-input-field"
                                name="image_file"
                                @change="selectCone()"
                                ref="corner_one"
                            />
                            <img v-if="corner_one_img_url" :src="corner_one_img_url" height="100" width="100" />
                        </div>
                    </div>
                    <div>
                        <div>
                            <label for="image_file"><h3>Upload Corner Image Two</h3></label>
                            <input
                                type="file"
                                class="image-input-field"
                                name="image_file"
                                @change="selectCtwo()"
                                ref="corner_two"
                            />
                            <img v-if="corner_two_img_url" :src="corner_two_img_url" height="100" width="100" />
                        </div>
                    </div>
                </form>
            </div>
            <h2><u>Products In The Scene</u></h2>
            <div>
                <div :key="trig_r">
                    <div v-if="pos_mode==1">
                        <img :src="top_img_url"  v-on:click="touchstart_top" />
                    </div>
                    <div v-if="pos_mode==2">
                        <img :src="corner_one_img_url"  v-on:click="touchstart_uno" />
                    </div>
                    <div v-if="pos_mode==3">
                        <img :src="corner_two_img_url"  v-on:click="touchstart_dos" />
                    </div>
                    <div v-else  v-for="product in raw_prods" :key="product[0]">
                        <div v-if="product[2]" class="product-card">
                            <div class="title-row">
                                <div><h3>{{product[1].name}}</h3></div>
                                <div>
                                    <button @click="toggleProductVisibility(product[0])" v-if="product[3]"><font-awesome-icon :icon="['fas','chevron-up']"/></button>
                                    <button @click="toggleProductVisibility(product[0])" v-else><font-awesome-icon :icon="['fas','chevron-down']"/></button>
                                </div>
                            </div>
                            <div v-if="product[3]">
                            <NewButton 
                            buttonType='normal'
                            buttonText='Change Position'
                            @click.native="changeProductPosition(product[0])"
                            />
                            <div>
                                <div>
                                    <div>Top Image LOC :({{raw_prods[product[0]][1].loc.top.xloc}},{{raw_prods[product[0]][1].loc.top.yloc}}) </div>
                                    <div>Corner Image One LOC :({{raw_prods[product[0]][1].loc.corner_one.xloc}},{{raw_prods[product[0]][1].loc.corner_one.yloc}})</div>
                                    <div>Corner Image Two LOC :({{raw_prods[product[0]][1].loc.corner_two.xloc}},{{raw_prods[product[0]][1].loc.corner_two.yloc}})</div>
                                </div>
                                <form @submit="dummySubmit()">
                                    <div>
                                    <label for="instance_name">Instance Name  : </label>
                                    <input type="text" class="input-field" name="instance_name" placeholder="Instance Name" v-model="raw_prods[product[0]][1].instance_name"/>
                                    </div>
                                    <div>
                                    <label for="scene_name">Name in Scene : </label>
                                    <input type="text" class="input-field" name="scene_name" placeholder="Scene Name.." v-model="raw_prods[product[0]][1].name_in_scene"/>
                                    </div>
                                    <div>
                                        <input type="checkbox"  v-model="raw_prods[product[0]][1].replaceable">
                                        <label>Is Replaceable</label>
                                    </div>
                                    <div>
                                        <input type="checkbox"  v-model="raw_prods[product[0]][1].demo_replaceable">
                                        <label>Is Demo Replaceable</label>
                                    </div>
                                </form>
                            </div>
                            <NewButton
                            buttonType='normal'
                            buttonText='Remove'
                            @click.native="deleteProduct(product[0])"
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div v-if="add_mode"><ProductAdder :add_function="addProduct" /></div>
                    <div v-else><NewButton 
                    buttonType='normal'
                    buttonText='Add A Product'
                    @click.native="toggleAdder()" /></div>
                </div>
            </div>
            <h2><u>Services In The Scene</u></h2>
            <div>
                <div :key="trig_r">
                    <div v-for="service in raw_servs" :key="service[0]">
                        <div v-if="service[2]" class="product-card">
                            <div class="title-row">
                                <div><h3>{{service[1].name}}</h3></div>
                                <div>
                                    <button @click="toggleServiceVisibility(service[0])" v-if="service[3]"><font-awesome-icon :icon="['fas','chevron-up']"/></button>
                                    <button @click="toggleServiceVisibility(service[0])" v-else><font-awesome-icon :icon="['fas','chevron-down']"/></button>
                                </div>
                            </div>
                            <div v-if="service[3]">
                            <form @submit="dummySubmit()">
                                <div>
                                    <label for="name">Service Name  : </label>
                                    <input type="text" class="input-field" name="name" placeholder="Name..." v-model="raw_servs[service[0]][1].name"/>
                                </div>
                                <div>
                                    <label for="description">Description : </label>
                                    <textarea
                                        name="description"
                                        rows="6"
                                        cols="200"
                                        placeholder="Description..."
                                        v-model="raw_servs[service[0]][1].description"
                                    >
                                    </textarea>
                                </div>
                                <div>
                                    <label>Service Type : </label>
                                    <select  v-model="raw_servs[service[0]][1].service_type">
                                        <option value=null disabled selected>Service Type</option>
                                        <option  v-for="type in s_types" :key="type[0]" :value="type[1]" >
                                            {{type[1]}}
                                        </option>
                                    </select>
                                </div>
                                <div>
                                    <label for="quantity">Quantity:</label>
                                    <input type="number" class="input-field"  name="quantity" v-model="raw_servs[service[0]][1].quantity">
                                </div>
                                <div>
                                    <label for="rate">Rate:</label>
                                    <input type="number" class="input-field"  name="rate" v-model="raw_servs[service[0]][1].rate">
                                </div>
                                <div>
                                    <label>Unit : </label>
                                    <select  v-model="raw_servs[service[0]][1].unit">
                                        <option value=null disabled selected>Unit</option>
                                        <option  v-for="unit in s_units" :key="unit[0]" :value="unit[1]" >
                                            {{unit[1]}}
                                        </option>
                                    </select>
                                </div>
                            </form>
                            <div v-if="raw_servs[service[0]][1].rate && raw_servs[service[0]][1].quantity">Price : {{raw_servs[service[0]][1].quantity*raw_servs[service[0]][1].rate}}</div>
                            
                            <div>
                                <NewButton buttonType='normal' buttonText='Delete Service' @click.native="deleteService(service[0])" />
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <NewButton buttonType='normal' buttonText='Add A Service' @click.native="addService()" />
                </div>
            </div>
            <h2><div class="submit-row"><u>Submit the Scene</u></div></h2>
            <div class="submit-row">
                <NewButton buttonType='normal' buttonText='Save Scene' @click.native="saveScene()" />
            </div>
        </div>
    </div>
</template>

<script>
import Api from 'utils/Api'
import {getCookie} from 'utils/getCookie'
import ProductAdder from 'components/ProductAdder'
import NewButton from 'components/NewButton'
export default {
    name:'SceneForm',
    components:{
        NewButton,
        ProductAdder,
    },
    data(){
        return this.initialState();
    
    },
    mounted(){
        Api.get('RealEstateManagement/developer/').then(response =>{
            let res = response.data.developer_data;
            this.developer_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        Api.get('CADVisualization/artist/').then(response =>{
            let res = response.data.artist_list;
            this.artist_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        Api.get('ServicesMS/service/get_service_units/').then(response =>{
            let res = response.data.service_unit_type_options;
            this.s_units = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        Api.get('ServicesMS/service/get_service_types/').then(response =>{
            let res = response.data.service_type_options;
            this.s_types=res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        
    },
    methods:{
        reset() {
        Object.assign(this.$data, this.initialState());
        },
        initialState(){
        return{
            save_form:{
                fp_id:null,
                rp_id:null,
                top_image:null,
                corner_one_image:null,
                corner_two_image:null,
                moodboard_id:null,
                artist_id:null,
                products:[],
                services:[],
            },
            moodboard_list:[],
            developer_list:[],
            s_units:[],
            s_types:[],
            artist_list:[],
            developer_id:null,
            projects_list:[],
            project_id:null,
            phase_list:[],
            phase_id:null,
            gisou_list:[],
            gisou_id:null,
            fp_list:[],
            fp_id:null,
            rp_list:[],
            rp_id:null,
            top_img_url:null,
            corner_one_img_url:null,
            corner_two_img_url:null,
            add_mode:false,
            p_counter:0,
            s_counter:0,
            raw_prods:[],
            raw_servs:[],
            trig_r:0,
            pos_mode:0,
            temp_vars:{
                id:null,
                xloc_t:0,
                yloc_t:0,
                xloc_u:0,
                yloc_u:0,
                xloc_d:0,
                yloc_d:0,
            },
        }
    },
        dummySubmit(e){
            e.preventDefault();
        },
        getNewProjects(){
            let dev_id = this.developer_id;
            let req_string = 'RealEstateManagement/developer-projects/?developer_id='+dev_id;
            Api.get(req_string).then(response =>{
            let res = response.data.project_data;
            this.projects_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        getNewPhase(){
            let project_id = this.project_id;
            let req_string = 'RealEstateManagement/projects-phase/?project_id='+project_id;
            Api.get(req_string).then(response =>{
            let res = response.data.phase_data;
            this.phase_list=res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        getNewGisou(){
            let phase_id = this.phase_id;
            let req_string = 'RealEstateManagement/gisou/?project_phase_id='+phase_id;
            Api.get(req_string).then(response =>{
            let res = response.data.gisou_data;
            this.gisou_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        getNewFloorplan(){
            let gisou_id = this.gisou_id;
            let req_string = 'RealEstateManagement/floor-plan/?gisou_id='+gisou_id;
            Api.get(req_string).then(response =>{
            let res = response.data.floor_plan_list;
            this.fp_list=res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        let mood_string = 'InteriorDesign/designer-moodboard/?gisou_id='+gisou_id;
            Api.get(mood_string).then(response =>{
            let res = response.data.moodboard_data;
            this.moodboard_list=res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        getNewRoomplans(){
            let fp_id = this.save_form.fp_id;
            let req_string = 'RealEstateManagement/room-plan/?floor_plan_id='+fp_id;
            Api.get(req_string).then(response =>{
            let res = response.data.room_plan_list;
            this.rp_list=res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        selectTop() {
            this.save_form.top_image = this.$refs.top_image.files[0];
            this.top_img_url =  window.URL.createObjectURL(this.save_form.top_image);
        },
        selectCone() {
            this.save_form.corner_one_image = this.$refs.corner_one.files[0];
            this.corner_one_img_url =  window.URL.createObjectURL(this.save_form.corner_one_image);
        },
        selectCtwo() {
            this.save_form.corner_two_image = this.$refs.corner_two.files[0];
            this.corner_two_img_url =  window.URL.createObjectURL(this.save_form.corner_two_image);
        },
        toggleAdder(){
            this.add_mode = true;
        },
        addProduct(prod){
            console.log(prod);
            let p_obj = {
                "id":prod.product_id,
                "name":prod.name,
                "image":prod.image,
                "loc":{
                    "top":{
                        "xloc":0,
                        "yloc":0,
                    },
                    "corner_one":{
                        "xloc":0,
                        "yloc":0,
                    },
                    "corner_two":{
                        "xloc":0,
                        "yloc":0,
                    },
                },
                "instance_name":null,
                "name_in_scene":null,
                "replaceable":true,
                "demo_replaceable":true,
            }
            let p_list = [];
            p_list.push(this.p_counter);
            p_list.push(p_obj);
            p_list.push(true);
            p_list.push(true);
            this.p_counter = this.p_counter+1;
            this.raw_prods.push(p_list);
            this.add_mode=false;
        },
        deleteProduct(i){
            for(let x=0;x<this.raw_prods.length;x++){
                if(this.raw_prods[x][0]==i){
                    this.raw_prods[x][2]=false;
                }
            }
            this.trig_r = this.trig_r+1;
        },
        changeProductPosition(i){
            this.temp_vars.id = i;
            if(this.top_img_url){
                this.pos_mode=1;
            }
            else{
                if(this.corner_one_img_url){
                    this.pos_mode=2
                }
                else{
                    if(this.corner_two_img_url){
                        this.pos_mode = 3
                    }
                    else{
                        this.assignInfo();
                    }
                }
            }
        },
        touchstart_top(e){
            //console.log(e);
            const target = e.target;
            const rect = target.getBoundingClientRect();
            const x = (e.clientX - rect.left)/(rect.right-rect.left);
            const y = (e.clientY - rect.top)/(rect.bottom-rect.top);
            this.temp_vars.xloc_t = x;
            this.temp_vars.yloc_t = y;
            //this.assignInfo();
            if(this.corner_one_img_url){
                this.pos_mode=2
            }
            else{
                if(this.corner_two_img_url){
                    this.pos_mode =3
                }
                else{
                    this.assignInfo();
                }
            }
            e.preventDefault();
        },
        touchstart_uno(e){
            //console.log(e);
            const target = e.target;
            const rect = target.getBoundingClientRect();
            const x = (e.clientX - rect.left)/(rect.right-rect.left);
            const y = (e.clientY - rect.top)/(rect.bottom-rect.top);
            this.temp_vars.xloc_u = x;
            this.temp_vars.yloc_u = y;
            //this.assignInfo();
            if(this.corner_two_img_url){
                this.pos_mode = 3
            }
            else{
                this.assignInfo();
            }
            e.preventDefault();
        },
        touchstart_dos(e){
            const target = e.target;
            const rect = target.getBoundingClientRect();
            const x = (e.clientX - rect.left)/(rect.right-rect.left);
            const y = (e.clientY - rect.top)/(rect.bottom-rect.top);
            this.temp_vars.xloc_d = x;
            this.temp_vars.yloc_d = y;
            this.assignInfo();
        },
        assignInfo(){
            this.pos_mode=0;
            this.raw_prods[this.temp_vars.id][1].loc.top.xloc = this.temp_vars.xloc_t;
            this.raw_prods[this.temp_vars.id][1].loc.top.yloc = this.temp_vars.yloc_t;
            this.raw_prods[this.temp_vars.id][1].loc.corner_one.xloc = this.temp_vars.xloc_u;
            this.raw_prods[this.temp_vars.id][1].loc.corner_one.yloc = this.temp_vars.yloc_u;
            this.raw_prods[this.temp_vars.id][1].loc.corner_two.xloc = this.temp_vars.xloc_d;
            this.raw_prods[this.temp_vars.id][1].loc.corner_two.yloc = this.temp_vars.yloc_d;
            this.temp_vars.id = null;
            this.temp_vars.xloc_t = 0;
            this.temp_vars.yloc_t = 0;
            this.temp_vars.xloc_u = 0;
            this.temp_vars.yloc_u = 0;
            this.temp_vars.xloc_d = 0;
            this.temp_vars.yloc_d = 0;
        },
        addService(){
            let s_obj = {
                "name":"service "+this.s_counter,
                "description":null,
                "service_type":null,
                "rate":null,
                "unit":null,
                "quantity":null,
            }
            let s_list = [];
            s_list.push(this.s_counter);
            this.s_counter = this.s_counter+1;
            s_list.push(s_obj);
            s_list.push(true);
            s_list.push(true);
            this.raw_servs.push(s_list);
        },
        deleteService(i){
            for(let x=0;x<this.raw_servs.length;x++){
                if(this.raw_servs[x][0]==i){
                    this.raw_servs[x][2]=false;
                }
            }
            this.trig_r = this.trig_r+1;
        },
        toggleServiceVisibility(i){
            for(let x=0;x<this.raw_servs.length;x++){
                if(this.raw_servs[x][0]==i){
                    this.raw_servs[x][3]=!this.raw_servs[x][3];
                }
            }
            this.trig_r = this.trig_r+1;
        },
        toggleProductVisibility(i){
            for(let x=0;x<this.raw_prods.length;x++){
                if(this.raw_prods[x][0]==i){
                    this.raw_prods[x][3]=!this.raw_prods[x][3];
                }
            }
            this.trig_r = this.trig_r+1;
        },
        saveScene(){
            for(let x=0;x<this.raw_servs.length;x++){
                if(this.raw_servs[x][2]){
                    this.save_form.services.push(this.raw_servs[x][1]);
                }
            }
            for(let x=0;x<this.raw_prods.length;x++){
                if(this.raw_prods[x][2]){
                    this.save_form.products.push(this.raw_prods[x][1]);
                }
            }
            let formData = new FormData();
            formData.append('top_image',this.save_form.top_image);
            formData.append('corner_image_one',this.save_form.corner_one_image);
            formData.append('corner_image_two',this.save_form.corner_two_image);
            //this.save_form.images = formData;
        
            console.log(this.save_form);
            Api.post(
                'CADVisualization/scene/',
                this.save_form,
                {
                headers:{
                    'X-CSRFToken': getCookie('csrftoken'),
                    //'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
                }
            }
            ).then(resp => {
                console.log(resp);
                if(resp.data.msg=="Scene added")
                {
                    let s_id = resp.data.scene_id;
                    let r_s = 'CADVisualization/scene/add_scene_images/?scene_id='+s_id;
                    Api.post(
                    r_s,
                    formData,
                    {
                        headers:{
                            'X-CSRFToken': getCookie('csrftoken'),
                            'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW'
                            }
                    }
                    ).then(resp=>
                    {
                        console.log(resp)
                        if(resp.data.status){
                            alert('added scene'+s_id);
                            this.$refs.top_image.value = null;
                            this.$refs.corner_one.value = null;
                            this.$refs.corner_two.value = null;
                            this.reset();
                            Api.get('RealEstateManagement/developer/').then(response =>{
            let res = response.data.developer_data;
            this.developer_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        Api.get('CADVisualization/artist/').then(response =>{
            let res = response.data.artist_list;
            this.artist_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        Api.get('ServicesMS/service/get_service_units/').then(response =>{
            let res = response.data.service_unit_type_options;
            this.s_units = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        Api.get('ServicesMS/service/get_service_types/').then(response =>{
            let res = response.data.service_type_options;
            this.s_types=res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
                        }
                    }
                    )
                }
                

            })
        },
        
    }
}
</script>

<style scoped>
.scene-form{
    padding:20px;
    margin:20px;
    border: 2px dotted #00c75b;
}
.filter-title{
    font-weight: 700;
}
h2{
    font-weight:bolder;
    border-bottom: 1px solid #00c75b;
    border-top: 1px solid #00c75b;
    margin-top:20px;
    margin-bottom:20px;
}
h3{
    font-weight:900;
}
.product-card{
    border: 1px solid #00c75b;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.input-field {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    font-size: 14px;
    margin-bottom: 15px;
}
textarea {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    font-size: 14px;
    margin-bottom: 15px;
}
.title-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
}
.submit-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
</style>