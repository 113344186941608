<template>
    <div class="product-list">
        <div class="row" v-if="notEditing">
            <div class="col">
                <div
                    class="n-row"
                    v-for="prod in product_list"
                    :key="prod.product_id"
                >
                    <div>
                        <ProductImageCard
                            type="normal"
                            :img_url="prod.image[0]"
                            :name="prod.name "
                        />
                    </div>
                    <div class="det-text">
                        <div>Name : {{ prod.name }}</div>
                        <div>Price : {{ prod.item_price }}</div>
                    </div>
                    <div class="det-edit">
                        <NewButton 
                        buttonType="normal"
                        buttonText="Edit Product"
                        @click.native="editProduct(prod)"
                        />
                    </div>
                </div>
                <div class="row">
                    <PaginateLargeButton
                        v-if="page != 1"
                        @click.native="filterByPage(page - 1)"
                    >
                        &#8592; Last Page
                    </PaginateLargeButton>
                    <span v-for="index in pages" :key="index">
                        <NewButton
                            :buttonText="index"
                            buttonType="pag-small"
                            @click.native="filterByPage(index)"
                        />
                    </span>

                    <PaginateLargeButton
                        v-if="page != pages"
                        @click.native="filterByPage(page + 1)"
                    >
                        Next Page &#8594;
                    </PaginateLargeButton>
                </div>
            </div>
            <div class="col">
                <u><h1 class="filter-title">Filter By Vendor</h1></u>
                <form>
                    <div
                        v-for="vendor in vendor_list.vendor_list"
                        v-bind:key="vendor.id"
                    >
                        <input
                            type="checkbox"
                            :name="vendor.name"
                            :id="vendor.id"
                            :value="vendor.id"
                            v-model="vendor_ids"
                        />
                        <label :for="vendor.name">{{ vendor.name }} </label
                        ><br />
                    </div>
                </form>
                <NewButton
                    buttonType="normal"
                    buttonText="Filter By Vendor"
                    @click.native="filterByVendor()"
                />
            </div>
        </div>
        <div class="row" v-else>
            <div>
                <form @submit="dummySubmit">
                    <label for="name">Name : </label>
                        <input
                            type="text"
                            class="input-field"
                            name="name"
                            placeholder="Product Name..."
                            v-model="editForm.name"
                        />
                        <br />
                    <label for="price">Price : </label>
                        <input
                            type="text"
                            class="input-field"
                            name="price"
                            placeholder="Product Price..."
                            v-model="editForm.item_price"
                        />
                        <br />
                    <label for="description">Description : </label>
                        <textarea
                            name="description"
                            rows="6"
                            cols="200"
                            placeholder="Description..."
                            v-model="editForm.description">
                        </textarea>
                        <br />
                    <label for="category">Pick Category : </label>
                        <select name="category" v-model="editForm.item_category">
                            <option
                            v-for="category in category_list.category_list"
                            v-bind:key="category.id"
                            :value="category.name"
                            >{{ category.name }}
                            </option>
                        </select>
                        <br />
                </form>
                <NewButton 
                buttonType="normal"
                buttonText="Submit"
                @click.native="submitProduct()"
                />
            </div>
            <div>
                <NewButton 
                buttonType="normal"
                buttonText="Cancel"
                @click.native="toggleEditing()"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Api from 'utils/Api'
import ProductImageCard from 'components/ProductImageCard'
import NewButton from 'components/NewButton'
import PaginateLargeButton from 'components/PaginateLargeButton'
export default {
    name: 'ProductList',
    components: {
        ProductImageCard,
        NewButton,
        PaginateLargeButton,
    },
    props: {
        vendor_list: {
            type: Object,
        },
        category_list: {
            type: Array,
        },
    },
    data() {
        return {
            product_list: null,
            pages: null,
            req_string: null,
            vendor_ids: [],
            page: 1,
            notEditing:true,
            editId:null,
            editForm:null,
        }
    },
    mounted() {
        Api.get('product/furnish-products/')
            .then(response => {
                console.log(response.data)
                this.product_list = response.data.furnish_data
                this.pages = response.data.num_pages
            })
            .catch(e => {
                console.log({
                    status: e.response.status,
                    data: e.response.data,
                })
            })
    },
    methods: {
        submitProduct(){
            console.log(this.editForm);
        },
        filterByPage(i) {
            this.page = i
            this.fetchProductList()
        },
        filterByVendor() {
            this.page = 1
            this.fetchProductList()
        },
        editProduct(prod){
            this.notEditing = false;
            this.editForm = prod;
            console.log(this.editForm);
        },
        toggleEditing(){
            this.notEditing = true;
        },
        fetchProductList() {
            let p = this.page
            let v_i = this.vendor_ids
            let v_s = ''
            let p_s = '?page=' + p
            if (v_i.length != 0) {
                let mid_s = ''
                for (let i = 0; i < v_i.length; i++) {
                    mid_s = mid_s + v_i[i] + ','
                }
                v_s = '&vendor_ids=' + mid_s + '99990'
            }
            this.req_string = p_s + v_s
            console.log(this.req_string)
            Api.get('product/furnish-products/' + this.req_string)
                .then(response => {
                    console.log(response.data)
                    this.product_list = response.data.furnish_data
                    this.pages = response.data.num_pages
                })
                .catch(e => {
                    console.log({
                        status: e.response.status,
                        data: e.response.data,
                    })
                })
        },
    },
}
</script>

<style scoped>
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.col {
    display: flex;
    flex-direction: column;
}
.n-row {
    display: flex;
    flex-direction: row;
    border: 3px solid #00c75b;
    border-radius: 10px;
    margin-bottom: 10px;
}
.product-list {
    padding: 20px;
    margin: 20px;
    border: 2px dotted #00c75b;
}
.det-text {
    width: 500px;
}
.filter-title {
    font-weight: 800;
}
.input-field {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    font-size: 14px;
    margin-bottom: 15px;
}
textarea {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    font-size: 14px;
    margin-bottom: 15px;
}
</style>
