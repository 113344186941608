<template>
    <div class="product-collection-form">
        <form>
            <label for="name">Name :   </label><input type="text"  class = "input-field" name="name" placeholder="Collection Name..." v-model="save_form.name" ><br>
            <label for="description">Description :   </label><textarea  name="description" rows = "6" cols="200" placeholder="Description..." v-model="save_form.description"></textarea><br>
            <select  v-model="save_form.theme_id">
                    <option value=null disabled selected>Themes</option>
                    <option :value="theme.id" v-for="theme in theme_data" :key="theme.id" >
                        {{theme.name}}
                    </option>
            </select>
        </form>
        <br>
        <div>
            Products In Collection
            <div v-for="product in products" :key="product.product_id">
                <button @click="deleteProduct(product)">
                    <div class="product-card">
                        <div>Product Id : {{product.product_id}}</div>
                        <div>Name : {{product.name}}</div>
                    </div>
                </button>
            </div>
        </div>
        <ProductAdder v-if="display_uploader==1"
        :add_function="addProduct"        
        />
        <div class="adder-button">
            <NewButton
            v-if="display_uploader==0" 
            buttonType='normal'
            buttonText='Add A Product'
            @click.native="toggleAdder()"
            />
        </div>
        <NewButton  buttonType="normal" buttonText="Save Collection" @click.native="saveCollection()"/>
    </div>
</template>

<script>
import ProductAdder from 'components/ProductAdder'
import NewButton from 'components/NewButton'
export default {
    name:'ProductCollectionForm',
    components:{
        NewButton,
        ProductAdder,
    },
    data(){
        return{
            save_form:{
                name:null,
                description:null,
                theme_id:null,
                product_list:[],
            },
            display_uploader:0,
            products:[],
        }
    },
    props:{
        theme_data:{
            type:Array
        }
    },
    methods:{
        toggleAdder(){
            this.display_uploader=1;
        },
        addProduct(product){
            this.save_form.product_list.push(product.product_id);
            this.products.push(product);
            this.display_uploader=0;
        },
        deleteProduct(product){
            this.save_form.product_list = this.save_form.product_list.filter((i)=>i!=product.product_id);
            this.products = this.products.filter((i)=>i!=product);
        },
        saveCollection(){
            console.log(this.save_form);
        }
    }
}
</script>

<style scoped>
.input-field{
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #fff;
	border-radius: 5px;
	border:1px solid #000;
	font-size: 14px;
    margin-bottom: 15px;
}
textarea{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff ;
    border-radius: 5px;
    border:1px solid #000;
    font-size:14px;
    margin-bottom:15px;
}
.product-card{
    display: flex;
    flex-direction: column;
    border: 2px solid #00c75b;
    width:400px;
    margin: 10px;
}
.adder-button{
    margin-bottom:10px;
}
.product-collection-form{
    padding:20px;
    margin:20px;
    border: 2px dotted #00c75b;
}
</style>