<template>
    <div class="product-adder">
        <div class="search-bar">
        <form @submit="dummySubmit()" >
            <input type="text"  class = "input-field"  placeholder="Search..." v-model="query" >
        </form>
        <button @click="searchProducts" ><font-awesome-icon :icon="['fas','search']"/></button>
        </div>
        <div v-for="product in products_list" :key="product.product_id">
            <button @click="chooseProduct(product)">
                <div v-if="product==selected" class="selected-product-card">
                    <div class='row'>
                                    <div>
                                        <ProductImageCard
                                            type="normal"
                                            :img_url="product.image[0]"
                                        />
                                    </div>
                                    <div>
                                        <div>Product Id : {{ product.product_id }}</div>
                                        <div>Name : {{ product.name }}</div>
                                    </div>
                    </div>
                </div>
                <div v-else class="product-card" >
                    <div class='row'>
                                    <div>
                                        <ProductImageCard
                                            type="normal"
                                            :img_url="product.image[0]"
                                        />
                                    </div>
                                    <div>
                                        <div>Product Id : {{ product.product_id }}</div>
                                        <div>Name : {{ product.name }}</div>
                                    </div>
                                </div>
                </div>
            </button>
        </div>
        <NewButton buttonText="Add Product" buttonType="normal" @click.native="add_function(selected)" />
    </div>
</template>

<script>
import Api from 'utils/Api'
import NewButton from 'components/NewButton'
import ProductImageCard from 'components/ProductImageCard'
export default {
    name:'ProductAdder',
    components:{
        NewButton,
        ProductImageCard,
    },
    data(){
        return{
            query:null,
            products_list:[],
            selected:null,
        }
    },
    props:{
        add_function:{
            type:Function
        }
    },
    methods:{
        searchProducts(){
            let query_string = "product/furnish-products/?search="+this.query;
            Api.get(query_string).then((resp)=>{
                let res = resp.data.furnish_data;
                if(res.length>5){
                    res = res.slice(0,5)
                }
                this.products_list = res;
                })
        },
        chooseProduct(obj){
            this.selected = obj;
        },
        dummySubmit(e){
            e.preventDefault();
        }
    }
}
</script>

<style scoped>
.product-adder{
    padding:20px;
    margin:20px;
    border: 2px dotted #00c75b;
}
.search-bar{
    display: flex;
    flex-direction: row;
}
.product-card{
    display: flex;
    flex-direction: column;
    border: 2px solid #000;
    width:400px;
    margin: 10px;
}
.selected-product-card{
    display: flex;
    flex-direction: column;
    border: 2px solid #00c75b;
    width:400px;
    margin: 10px;

}
.row {
    display: flex;
    flex-direction: row;
}

</style>