<template>
    <span>
        <button v-if="buttonType=='imageButton'" class='imgbtn'>
            {{buttonText}}
        </button>
        <button v-if="buttonType=='normal'" class='btn'>
            {{buttonText}}
        </button>
        <button v-if="buttonType=='pag-small'" class='pag-small'>
            {{buttonText}}
        </button>
    </span>
</template>

<script>
export default {
    name:'NewButton',
    props:{
        buttonText:{
            type:String
        },
        buttonType:{
            type:String 
        },
    }
    
}
</script>

<style lang="scss" scoped>
@use 'styles';
.btn{
    color: #323232;
    font-size: 12px;
    border: solid #323232;
    font-weight: bolder;
    padding:15px 24px;
    font-family: 'Poppins', sans-serif;
    box-shadow: inset 0 0 0 0 styles.$primary;
    -webkit-transition: ease-out 0.6s;
    -moz-transition: ease-out 0.6s;
    transition: ease-out 0.6s;
}
.btn:hover{
    box-shadow: inset 0 100px 0 0 styles.$primary;
    color: white;
    border: solid styles.$primary;
}
.imgbtn{
    color: #323232;
    font-size: 12px;
    border: solid #323232;
    background: white;
    font-weight: bolder;
    padding:15px 24px;
    font-family: styles.$primary-font;
    box-shadow: inset 0 0 0 0 styles.$primary;
    -webkit-transition: ease-out 0.6s;
    -moz-transition: ease-out 0.6s;
    transition: ease-out 0.6s;
}
.imgbtn:hover{
    box-shadow: inset 0 100px 0 0 styles.$primary;
    color: white;
    border: solid styles.$primary;
}
.pag-small{
    height:25px;
    width:25px;
    background-color: styles.$primary;
    color:#fff;
}
</style>