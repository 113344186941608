import Api from 'utils/Api'

export function getFilterThemes(){
    return Api
        .get('InteriorDesign/theme/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}

export function getFilterRoomTypes(){
    return Api
        .get('RealEstateManagement/room-plan/room_type/')
        .then(response => {
            return response.data
        })
        .catch(e => {
            return { status: e.response.status, data: e.response.data }
        })
}
