<template>
    <div class="scene-collection-form">
        <div>
            <form @submit="dummySubmit()">
                <label for="name">Name :   </label><input type="text"  class = "input-field" name="name" placeholder="Collection Name..." v-model="save_form.name" ><br>
                <label for="description">Description :   </label><textarea  name="description" rows = "6" cols="200" placeholder="Description..." v-model="save_form.description"></textarea><br>
                <div>
                    <u><h1 class="filter-title">Select Collection Type</h1></u>
                    <select @change="resetType()"  v-model="save_form.collection_type">
                        <option value=null disabled selected>Collection Types</option>
                        <option v-for="type in collection_types" :key="type" >
                            {{type}}                            
                        </option>
                    </select>
                </div>
                <div v-if="save_form.collection_type=='THEME'">
                    <u><h1 class="filter-title">Select Theme</h1></u>
                    <select v-model="save_form.theme_id">
                        <option value=null disabled selected>Themes</option>
                        <option :value="theme.id" v-for="theme in theme_data" :key="theme.id" >
                            {{theme.name}}
                        </option>
                    </select>
                </div>
                <div v-if="save_form.collection_type=='ROOM_TYPE'">
                    <u><h1 class="filter-title">Select Room Type</h1></u>
                    <select  v-model="save_form.room_type">
                        <option value=null disabled selected>Room Types</option>
                        <option  v-for="type in room_type_data" :key="type" >
                            {{type.category}}
                        </option>
                    </select>
                </div>
            </form>
            
            <div v-if="selected_scenes_list.length>0">
                Scenes in Collection
                <div v-for="scene in selected_scenes_list" :key="scene.id">
                    <button @click="deleteScene(scene)">
                        <div class = "scene-card">
                            <div>Id : {{scene.id}}</div>
                            <div>{{scene.theme_name}} {{scene.room_type}}</div>
                            <div>Designed by {{scene.designer_name}}</div>
                        </div>
                    </button>
                </div>
            </div>
            <NewButton @click.native="submitForm()" buttonText='Submit' buttonType='normal'/>

            <div class = "row">
                <div>
                    <form @submit="dummySubmit()">
                        <div>
                            <u><h1 class="filter-title">Select Developer</h1></u>
                            <select @change="getNewProjects()"  v-model="developer_id">
                                <option value=null disabled selected>Developers</option>
                                <option  v-for="dev in developer_list" :key="dev.id" :value="dev.id" >
                                    {{dev.name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <u><h1 class="filter-title">Select Project</h1></u>
                            <select @change='getNewPhase()'  v-model="project_id">
                                <option value=null disabled selected>Projects</option>
                                <option  v-for="project in projects_list" :key="project.id" :value="project.id" >
                                    {{project.name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <u><h1 class="filter-title">Select Phase</h1></u>
                            <select @change='getNewGisou()'  v-model="phase_id">
                                <option value=null disabled selected>Phases</option>
                                <option  v-for="phase in phase_list" :key="phase.id" :value="phase.id" >
                                    {{phase.name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <u><h1 class="filter-title">Select GI Set of Units</h1></u>
                            <select @change='getNewFloorplan()'  v-model="gisou_id">
                                <option value=null disabled selected>GI Sets of Units</option>
                                <option  v-for="gisou in gisou_list" :key="gisou.id" :value="gisou.id" >
                                    {{gisou.building_name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <u><h1 class="filter-title">Select FloorPlan</h1></u>
                            <select @change='getNewRoomplans()'  v-model="fp_id">
                                <option value=null disabled selected>Floor Plans</option>
                                <option  v-for="fp in fp_list" :key="fp.id" :value="fp.id" >
                                    {{fp.name}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <u><h1 class="filter-title">Select RoomPlan</h1></u>
                            <select @change='getScenes()'  v-model="rp_id">
                                <option value=null disabled selected>Room Plans</option>
                                <option  v-for="rp in rp_list" :key="rp.id" :value="rp.id" >
                                    {{rp.room_type}}
                                </option>
                            </select>
                        </div>
                    </form>
                </div>
                <div>
                    <div v-for="scene in scene_list" :key="scene.id">
                        <button @click="selectScene(scene)" v-if="!save_form.scene_ids.includes(scene.id)">
                            <div class = "scene-card">
                                <div>Id : {{scene.id}}</div>
                                <div>{{scene.theme_name}} {{scene.room_type}}</div>
                                <div>Designed by {{scene.designer_name}}</div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from 'utils/Api'
import NewButton from 'components/NewButton'

export default {
    name:"SceneCollectionForm",
    components:{
        NewButton,
    },
    props:{
        theme_data:{
            type:Array
        },
        room_type_data:{
            type:Array
        }
    },
    data(){
        return{
            save_form:{
                name:null,
                description:null,
                collection_type:null,
                theme_id:null,
                room_type:null,
                scene_ids:[],
                },
            collection_types:[],
            developer_list:[],
            developer_id:null,
            projects_list:[],
            project_id:null,
            phase_list:[],
            phase_id:null,
            gisou_list:[],
            gisou_id:null,
            fp_list:[],
            fp_id:null,
            rp_list:[],
            rp_id:null,
            scene_list:[],
            selected_scenes_list:[],
        }
    },
    mounted(){
        Api.get('CADVisualization/collection-of-scene/get_collection_types/').then(response =>{
            let res = response.data.collection_type_options;
            for(let i=0;i<res.length;i++){
                this.collection_types.push(res[i][0]);
            }
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        Api.get('RealEstateManagement/developer/').then(response =>{
            let res = response.data.developer_data;
            this.developer_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
    },
    methods:{
        dummySubmit(e){
            e.preventDefault();
        },
        resetType(){
            this.save_form.theme_id=null;
            this.save_form.room_type=null;
        },
        getNewProjects(){
            let dev_id = this.developer_id;
            let req_string = 'RealEstateManagement/developer-projects/?developer_id='+dev_id;
            Api.get(req_string).then(response =>{
            let res = response.data.project_data;
            this.projects_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        getNewPhase(){
            let project_id = this.project_id;
            let req_string = 'RealEstateManagement/projects-phase/?project_id='+project_id;
            Api.get(req_string).then(response =>{
            let res = response.data.phase_data;
            this.phase_list=res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        getNewGisou(){
            let phase_id = this.phase_id;
            let req_string = 'RealEstateManagement/gisou/?project_phase_id='+phase_id;
            Api.get(req_string).then(response =>{
            let res = response.data.gisou_data;
            this.gisou_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        getNewFloorplan(){
            let gisou_id = this.gisou_id;
            let req_string = 'RealEstateManagement/floor-plan/?gisou_id='+gisou_id;
            Api.get(req_string).then(response =>{
            let res = response.data.floor_plan_list;
            this.fp_list=res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        getNewRoomplans(){
            let fp_id = this.fp_id;
            let req_string = 'RealEstateManagement/room-plan/?floor_plan_id='+fp_id;
            Api.get(req_string).then(response =>{
            let res = response.data.room_plan_list;
            this.rp_list=res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        getScenes(){
            let rp_id = this.rp_id;
            let req_string = 'CADVisualization/scene/?room_plan_id='+rp_id;
            Api.get(req_string).then(response =>{
            let res = response.data.scene_data;
            this.scene_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
            
        },
        selectScene(obj){
            this.save_form.scene_ids.push(obj.id);
            this.selected_scenes_list.push(obj);
        },
        deleteScene(obj){
            this.save_form.scene_ids = this.save_form.scene_ids.filter((i)=>i!=obj.id);
            this.selected_scenes_list = this.selected_scenes_list.filter((i)=>i.id!=obj.id);
        },
        submitForm(){
            console.log(this.save_form);
        }
        
    }   
}
</script>

<style scoped>
.input-field{
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #fff;
	border-radius: 5px;
	border:1px solid #000;
	font-size: 14px;
    margin-bottom: 15px;
}
.scene-collection-form{
    padding:20px;
    margin:20px;
    border: 2px dotted #00c75b;
}
textarea{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff ;
    border-radius: 5px;
    border:1px solid #000;
    font-size:14px;
    margin-bottom:15px;
}
.filter-title{
    font-weight: 800;
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border:1px solid #00c75b;
    padding: 20px;
}
.scene-card{
    display: flex;
    flex-direction: column;
    border:1px solid #00c75b;
}
</style>