<template>
    <div class="scene-collection-list">
        <div class="row">
            <div>
                <div class="collection-card" v-for="collection in collection_list" :key="collection.id">
                    <div><span class="head">Name : </span><span>{{collection.name}}</span></div>
                    <div><span class="head">Collection Type : </span><span>{{collection.collection_type}}</span></div>
                    <div v-if="collection.theme"><span class="head">Theme : </span><span>{{collection.theme}}</span></div>
                    <div v-if="collection.room_type"><span class="head">Room Type : </span><span>{{collection.room_type}}</span></div>
                    <div><p>{{collection.description}}</p></div>
                    <div><span class="head">Scenes in Collection : </span><span>{{collection.num_scenes}}</span></div>
                </div>
            </div>
            <div>
                <form @submit="dummySubmit()">
                    <div>
                        <u><h1 class="filter-title">Filter By Collection Type</h1></u>
                        <select @change="filterByType()" v-model="collection_type">
                            <option value=null disabled selected>Collection Types</option>
                            <option value=all>All</option>
                            <option v-for="type in collection_types" :key="type" >
                                {{type}}
                            </option>
                        </select>
                    </div>
                    <div v-if="collection_type=='THEME'">
                        <u><h1 class="filter-title">Filter By Theme</h1></u>
                        <select @change="filterByTheme()" v-model="theme_id">
                            <option value=null disabled selected>Themes</option>
                            <option value="all">All</option>
                            <option :value="theme.id" v-for="theme in theme_data" :key="theme.id" >
                                {{theme.name}}
                            </option>
                        </select>
                    </div>
                    <div v-if="collection_type=='ROOM_TYPE'">
                        <u><h1 class="filter-title">Filter By Room Type</h1></u>
                        <select @change="filterByRoomType()" v-model="room_type">
                            <option value=null disabled selected>Room Types</option>
                            <option value="all">All</option>
                            <option  v-for="type in room_type_data" :key="type" >
                                {{type.category}}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Api from 'utils/Api'
export default {
    name:'SceneCollectionList',
    data(){
        return{
            collection_list:[],
            collection_types:[],
            collection_type:null,
            theme_id:null,
            room_type:null,
        }
    },
    props:{
        theme_data:{
            type:Array
        },
        room_type_data:{
            type:Array
        }
    },
    mounted(){
        Api.get('CADVisualization/collection-of-scene/get_collection_list/').then(response => {
            this.collection_list = response.data.collection_list;
        }).catch(e => {
            console.log({ status: e.response.status, data: e.response.data }) 
        })
        Api.get('CADVisualization/collection-of-scene/get_collection_types/').then(response =>{
            let res = response.data.collection_type_options;
            for(let i=0;i<res.length;i++){
                this.collection_types.push(res[i][0]);
            }
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
    },
    methods:{
        dummySubmit(e){
            e.preventDefault();
        },
        filterByType(){
            let req_string = 'CADVisualization/collection-of-scene/get_collection_list/';
            if(this.collection_type!='all'){
                req_string = req_string+'?collection_type='+this.collection_type;
            }
            Api.get(req_string).then(response => {
            this.collection_list = response.data.collection_list;
        }).catch(e => {
            console.log({ status: e.response.status, data: e.response.data }) 
        })
        },
        filterByTheme(){
            let req_string = 'CADVisualization/collection-of-scene/get_collection_list/?collection_type=THEME';
            if(this.theme_id!='all'){
                req_string = req_string+'&theme_id='+this.theme_id;
            }
            Api.get(req_string).then(response => {
            this.collection_list = response.data.collection_list;
        }).catch(e => {
            console.log({ status: e.response.status, data: e.response.data }) 
        })
        },
        filterByRoomType(){
            let req_string = 'CADVisualization/collection-of-scene/get_collection_list/?collection_type=ROOM_TYPE';
            if(this.room_type!='all'){
                req_string = req_string+'&room_type='+this.room_type;
            }
            Api.get(req_string).then(response => {
            this.collection_list = response.data.collection_list;
        }).catch(e => {
            console.log({ status: e.response.status, data: e.response.data }) 
        })
        },
    }
}
</script>

<style scoped>
.collection-card{
    display: flex;
    flex-direction: column;
    border:3px solid #00c75b;
    margin:10px;
    border-radius: 5px;
    padding: 15px;
}
.head{
    font-weight: bold;
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.scene-collection-list{
    padding:20px;
    margin:20px;
    border: 2px dotted #00c75b;
}
.filter-title{
    font-weight: 800;
}

</style>