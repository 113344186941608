<template>
    <div class="product-form">
        <form>
            <form @submit="fetchData">
                <label for="vendors">Pick Vendor : </label>
                <select
                    @change="setVendorID($event)"
                    name="vendors"
                    v-model="vendor_data"
                >
                    <option
                        v-for="vendor in vendor_list.vendor_list"
                        v-bind:key="vendor.id"
                        :value="[vendor.id, vendor.name]"
                        >{{ vendor.name }}</option
                    > </select
                ><br /><br />
                <label for="URL">Product URL : </label>
                <div class="urlbox">
                    <input
                        type="text"
                        class="input-field"
                        name="URL"
                        placeholder="URL..."
                        v-model="fetch_form.url"
                    />
                    <NewButton
                        type="submit"
                        buttonType="normal"
                        buttonText="Fetch Data from URL"
                    />
                </div>
                <br />
            </form>
            <label for="name">Name : </label
            ><input
                type="text"
                class="input-field"
                name="name"
                placeholder="Product Name..."
                v-model="fetch_form.name"
            /><br />
            <label for="description">Description : </label
            ><textarea
                name="description"
                rows="6"
                cols="200"
                placeholder="Description..."
                v-model="fetch_form.description"
            ></textarea
            ><br />
            <label for="price">Price : </label
            ><input
                type="text"
                class="input-field"
                name="price"
                placeholder="Price..."
                v-model="fetch_form.price"
            /><br />
            <label for="discounted_price">Discounted Price : </label
            ><input
                type="text"
                class="input-field"
                name="discounted_price"
                placeholder="Discounted Price..."
                v-model="fetch_form.discounted_price"
            /><br />
            <label for="category">Pick Category : </label>
            <select name="category" v-model="fetch_form.category_id">
                <option
                    v-for="category in category_list.category_list"
                    v-bind:key="category.id"
                    :value="category.id"
                    >{{ category.name }}</option
                > </select
            ><br />
        </form>
        <div v-if="fetch_form.name != null">
            <div>Add Related Products :</div>
            <div
                v-for="product in fetch_form.explicitly_related_products"
                :key="product.product_id"
            >
                <div>
                    <button @click="removeRelatedProduct(product)">
                        <div class="product-card">
                            <div class='row'>
                                    <div>
                                        <ProductImageCard
                                            type="normal"
                                            :img_url="product.image[0]"
                                        />
                                    </div>
                                    <div>
                                        <div>Product Id : {{ product.product_id }}</div>
                                        <div>Name : {{ product.name }}</div>
                                    </div>
                                </div>
                        </div>
                    </button>
                </div>
            </div>
            <div v-if="suggested_related_products.length != 0">
                <div>We think these products would be a good match</div>
                <div
                    v-for="product in suggested_related_products"
                    :key="product.product_id"
                >
                    <div>
                        <button @click="chooseSuggestedProduct(product)">
                            <div
                                v-if="
                                    !fetch_form.explicitly_related_products.includes(
                                        product,
                                    )
                                "
                                class="product-card"
                            >
                                <div class='row'>
                                    <div>
                                        <ProductImageCard
                                            type="normal"
                                            :img_url="product.image[0]"
                                        />
                                    </div>
                                    <div>
                                        <div>Product Id : {{ product.product_id }}</div>
                                        <div>Name : {{ product.name }}</div>
                                    </div>
                                </div>
                                
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <ProductAdder
                        v-if="display_adder == 1"
                        :add_function="chooseSuggestedProduct"
                    />
                    <NewButton
                        v-if="display_adder == 0"
                        buttonType="normal"
                        buttonText="Add More Products"
                        @click.native="toggleAdder(1)"
                    />
                </div>
            </div>
        </div>
        <label for="scraped_dimensions">Dimensions : </label
        ><input
            type="text"
            class="input-field"
            name="scraped_dimensions"
            placeholder="Dimensions..."
            v-model="fetch_form.scraped_dimensions"
        /><br />
        <div class="dimension-entry-field">
            <label for="structured_dimensions">Add Dimensions : </label
            ><NewButton
                @click.native="createDimField()"
                buttonType="normal"
                buttonText="Add Field"
            />
        </div>
        <div v-for="i in val.length" :key="i" class="row">
            <div class="dim-field">
                <select v-model="dim[i - 1]">
                    <option value="" disabled selected>Dimension Type</option>
                    <option
                        v-for="(value,
                        propertyName) in dimension_options.dimension_options"
                        :key="propertyName"
                        >{{ propertyName }}</option
                    >
                </select>
            </div>
            <div class="dim-field">
                <input
                    type="text"
                    class="input-field"
                    placeholder="Value..."
                    v-model="val[i - 1]"
                />
            </div>
            <div class="dim-field">
                <select v-model="unit[i - 1]">
                    <option value="" disabled selected>Dimension Unit</option>
                    <option
                        v-for="unit in dimension_options.dimension_options[
                            dim[i - 1]
                        ]"
                        :key="unit"
                        >{{ unit }}</option
                    >
                </select>
            </div>
        </div>

        <div class="image-col" v-if="image_list[0]">
            <h1>Select Primary Image</h1>
            <div class="image-row">
                <div v-for="img in image_list" :key="img">
                    <ProductImageCard
                        :img_url="img"
                        type="selected"
                        v-if="fetch_form.image_url == img"
                        :value="img"
                        @click.native="addPrimaryImage(img)"
                    />
                    <ProductImageCard
                        :img_url="img"
                        type="normal"
                        v-else
                        :value="img"
                        @click.native="addPrimaryImage(img)"
                    />
                </div>
            </div>
        </div>
        <div v-else>
            <label for="image_url">Image URL : </label>
            <input
                type="text"
                class="input-field"
                name="image_url"
                placeholder="Image URL..."
                v-model="fetch_form.image_url"
            /><br />
            <label for="image_file">Upload Image</label>
            <input
                type="file"
                class="image-input-field"
                name="image_file"
                @change="selectFile()"
                ref="image_file"
            />
        </div>

        <div class="image-col" v-if="image_list[0]">
            <h1>Select Secondary Images</h1>
            <div class="image-row">
                <div v-for="img in image_list" :key="img">
                    <ProductImageCard
                        :img_url="img"
                        type="selected"
                        v-if="
                            fetch_form.secondary_images.includes(img) &&
                                fetch_form.image_url != img
                        "
                        :value="img"
                        @click.native="addSecondaryImage(img)"
                    />
                    <ProductImageCard
                        :img_url="img"
                        type="normal"
                        v-else-if="
                            fetch_form.image_url != img &&
                                !fetch_form.secondary_images.includes(img)
                        "
                        :value="img"
                        @click.native="addSecondaryImage(img)"
                    />
                </div>
            </div>
        </div>
        <div class="btn-row">
            <NewButton
                @click.native="displayData"
                buttonType="normal"
                buttonText="Submit"
            />
        </div>
    </div>
</template>

<script>
import Api from 'utils/Api'
import {getCookie} from 'utils/getCookie'
import NewButton from 'components/NewButton'
import ProductImageCard from 'components/ProductImageCard'
import ProductAdder from 'components/ProductAdder'
export default {
    name: 'ProductForm',
    components: {
        NewButton,
        ProductImageCard,
        ProductAdder,
    },
    props: {
        vendor_list: {
            type: Object,
        },
        category_list: {
            type: Array,
        },
        dimension_options: {
            type: Object,
        },
    },
    data() {
        return {
            fetch_form: {
                vendor_id: null,
                vendor_name: null,
                url: null,
                name: null,
                description: null,
                price: null,
                discounted_price: null,
                image_url: null,
                secondary_images: [],
                dimensions: [],
                category_id: null,
                image_file: null,
                scraped_dimensions: null,
                explicitly_related_products: [],
            },
            image_list: [],
            vendor_data: [],
            dim: [],
            val: [],
            unit: [],
            suggested_related_products: [],
            display_adder: 0,
        }
    },
    methods: {
        fetchData(e) {
            e.preventDefault()
            Api.post('Scraper/productData/', {
                url: this.fetch_form.url,
                vendor_name: this.fetch_form.vendor_name,
            },
            {
                headers:{
                    'X-CSRFToken': getCookie('csrftoken')
                }
            }
            ).then(resp => {
                let res = resp.data.scraped_data.prod_data
                this.fetch_form.name = res.name
                this.fetch_form.description = res.description
                this.fetch_form.price = res.price
                this.fetch_form.discounted_price = res.discounted_price
                this.fetch_form.scraped_dimensions = res.dimension
                this.image_list = res.img_urls
                this.getRelatedProducts()
            })
        },
        getRelatedProducts() {
            let req_string =
                'product/furnish-products/get_related_products/?name=' +
                this.fetch_form.name +
                '&description=' +
                this.fetch_form.description
            Api.get(req_string).then(resp => {
                console.log(resp.data.related_products)
                this.suggested_related_products = resp.data.related_products
            })
        },
        displayData(e) {
            e.preventDefault()
            let status = ''
            console.log(this.fetch_form)
            for (let i = 0; i < this.val.length; i++) {
                let res_dim_val = 
                {
                    "dimension_type": this.dim[i],
                    "unit": this.unit[i],
                    "value": this.val[i]
                }
                this.fetch_form.dimensions.push(res_dim_val);
            }
            console.log(this.fetch_form)
            Api.post(
                'Scraper/productData/save_furnish_product/',
                this.fetch_form,
                {
                headers:{
                    'X-CSRFToken': getCookie('csrftoken')
                }
            }
            ).then(resp => {
                console.log(resp)
                status = resp.status
                console.log(status)
                if (status == 200) {
                    alert('added ' + this.fetch_form.name + ' to the database')
                    this.fetch_form.vendor_id = null
                    this.fetch_form.vendor_name = null
                    this.fetch_form.url = null
                    this.fetch_form.name = null
                    this.fetch_form.description = null
                    this.fetch_form.price = null
                    this.fetch_form.discounted_price = null
                    this.fetch_form.image_url = null
                    this.fetch_form.secondary_images = []
                    this.fetch_form.dimensions = []
                    this.fetch_form.category_id = null
                    this.fetch_form.image_file = null
                    this.fetch_form.scraped_dimensions = null
                    this.image_list = []
                    this.vendor_data = []
                    this.dim = []
                    this.val = []
                    this.unit = []
                    this.fetch_form.explicitly_related_products = []
                }
            })
        },
        addPrimaryImage(e) {
            if (this.fetch_form.secondary_images.includes(e)) {
                this.fetch_form.secondary_images = this.fetch_form.secondary_images.filter(
                    i => i != e,
                )
            }
            if (this.fetch_form.image_url == e) {
                this.fetch_form.image_url = null
            } else {
                this.fetch_form.image_url = e
            }
        },
        addSecondaryImage(e) {
            let img_url = e
            if (!this.fetch_form.secondary_images.includes(img_url)) {
                this.fetch_form.secondary_images.push(img_url)
            } else {
                this.fetch_form.secondary_images = this.fetch_form.secondary_images.filter(
                    i => i != img_url,
                )
            }
        },
        setVendorID(e) {
            e.preventDefault
            this.fetch_form.vendor_id = this.vendor_data[0]
            this.fetch_form.vendor_name = this.vendor_data[1]
        },
        selectFile() {
            this.fetch_form.image_file = this.$refs.image_file.files[0]
        },
        createDimField() {
            this.val.push(0)
        },
        chooseSuggestedProduct(prod) {
            if (!this.fetch_form.explicitly_related_products.includes(prod)) {
                this.fetch_form.explicitly_related_products.push(prod)
            }
            this.display_adder = 0
        },
        removeRelatedProduct(prod) {
            this.fetch_form.explicitly_related_products = this.fetch_form.explicitly_related_products.filter(
                i => i != prod,
            )
        },
        toggleAdder(i) {
            this.display_adder = i
        },
    },
}
</script>

<style scoped>
.row {
    display: flex;
    flex-direction: row;
}
.urlbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.input-field {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    font-size: 14px;
    margin-bottom: 15px;
}
.image-input-field {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    font-size: 14px;
    margin-bottom: 15px;
}
textarea {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    font-size: 14px;
    margin-bottom: 15px;
}
select {
    font-family: 'Lato', sans-serif !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 2px;
    background-size: 2rem;
    background-color: #d8d8d8;
}
.image-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 15px;
    margin-top: 7px;
}
.image-col {
    display: flex;
    flex-direction: column;
}
.btn-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.product-form {
    padding: 20px;
    margin: 20px;
    border: 2px dotted #00c75b;
}
.dim-field {
    padding-right: 50px;
}
.dimension-entry-field {
    padding-top: 10px;
    padding-bottom: 10px;
}
.product-card {
    display: flex;
    flex-direction: column;
    border: 2px solid #000;
    width: 400px;
    margin: 10px;
}
.selected-product-card {
    display: flex;
    flex-direction: column;
    border: 2px solid #00c75b;
    width: 400px;
    margin: 10px;
}
</style>
