<template>
    <div class="boq-form">
        <div>
            <form @submit="dummySubmit()">
                <div>
                <label for="name">BOQ Name :   </label><input type="text"  class = "input-field" name="name" placeholder="Name..." v-model="save_form.name" ><br>
                </div>
                <div>
                    <u><h1 class="filter-title">Select Theme</h1></u>
                    <select @change="getMoodBoards()" v-model="save_form.theme_id">
                        <option value=null disabled selected>Themes</option>
                        <option :value="theme.id" v-for="theme in theme_data" :key="theme.id" >
                            {{theme.name}}
                        </option>
                    </select>
                </div>
                <div>
                    <u><h1 class="filter-title">Select MoodBoard</h1></u>
                    <select @change="makeRoomTypeFilters()"  v-model="moodboard_data">
                        <option value=null disabled selected>MoodBoards</option>
                        <option :value="[mood.id,mood.gisou_id]" v-for="mood in moodboard_list" :key="mood.id" >
                            {{mood.name}}
                        </option>
                    </select>
                </div>
            </form>
            <div v-if="save_form.moodboard_id!=null" class="product-comp">
                <div class="filter-title" v-if="save_form.products.length!=0">Products in BOQ</div>
                <form @submit="dummySubmit()">
                    <div class="product-display" v-for="product in save_form.products" :key="product[1]" >
                        <div>{{product[2]}}</div>
                        <div>
                            <select  v-model="save_form.products[product[0]][3]">
                                <option value=null disabled selected>Select RoomPlan</option>
                                <option :value="room[0]" v-for="room in room_list" :key="room[0]" >
                                    {{room[1]}}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label for="quantity">Quantity :   </label><input type="text"  class = "input-field" name="quantity" placeholder="Collection Name..." v-model="save_form.products[product[0]][4]" ><br>
                        </div>
                        <!--<div class="last-button">
                            <button type="button" @click.native='deleteProduct(product[0])'><font-awesome-icon :icon="['fas','eraser']"/></button>
                        </div>-->
                    </div>
                </form>
                <NewButton v-if="save_form.products.length!=0" buttonType="normal" buttonText="Save BOQ" @click.native="submitForm()"/>
            </div>
            <div v-if="save_form.moodboard_id!=null" class="product-comp">
                <ProductAdder v-if="display_adder==1" :add_function="addProduct" />
                <NewButton v-if="display_adder==0" buttonText="Add Products" buttonType="normal" @click.native='toggleAdder(1)'/>
            </div>
        </div>
    </div>
</template>

<script>
import Api from 'utils/Api'
import {getCookie} from 'utils/getCookie'
import ProductAdder from 'components/ProductAdder'
import NewButton from 'components/NewButton'
export default {
    name:'BOQForm',
    components:{
        ProductAdder,
        NewButton,
    },
    props:{
        theme_data:{
            type:Array
        }
    },
    data(){
        return{
            save_form:{
                name:null,
                theme_id:null,
                moodboard_id:null,
                products:[],
            },
            moodboard_list:[],
            moodboard_data:null,
            room_list:[],
            display_adder:0,
        }
    },
    methods:{
        dummySubmit(e){
            e.preventDefault();
        },
        getMoodBoards(){
            let theme_id = this.save_form.theme_id;
            let req_string = 'InteriorDesign/designer-moodboard/?theme_id='+theme_id;
            Api.get(req_string).then(response =>{
            let res = response.data.moodboard_data;
            this.moodboard_list = res;
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
        },
        makeRoomTypeFilters(){
            this.save_form.moodboard_id = this.moodboard_data[0];
            let gisou_id = this.moodboard_data[1];
            let req_string = 'RealEstateManagement/floor-plan/?gisou_id='+gisou_id;
            Api.get(req_string).then(response =>{
            let res = response.data.floor_plan_list;
            for(let i=0;i<res.length;i++){
                let fp_id = res[i].id;
                let fp_name = res[i].name;
                let req = 'RealEstateManagement/room-plan/?floor_plan_id='+fp_id;
                Api.get(req).then(response =>{
            let resp = response.data.room_plan_list;
            for(let j = 0;j<resp.length;j++){
                let rp_id = resp[j].id;
                let rp_name = fp_name+' '+resp[j].name;
                this.room_list.push([rp_id,rp_name]);
            }
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})
            }
        }).catch(e=>{console.log({ status: e.response.status, data: e.response.data })})

        },
        toggleAdder(i){
            this.display_adder = i
        },
        addProduct(product){
            this.save_form.products.push([this.save_form.products.length,product.product_id,product.name,null,1]);
            this.display_adder = 0;
        },
        submitForm(){
            Api.post('Scraper/productData/save_boq/',
            this.save_form,
            {
                headers:{
                    'X-CSRFToken': getCookie('csrftoken')
                }
            }
            ).then((resp)=>{
                let status = resp.status;
                 if(status==200){
            alert('added '+this.save_form.name+' to the database')
            this.save_form.theme_id=null;
            this.save_form.moodboard_id=null;
            this.save_form.name=null;
            this.save_form.products=[];
            this.moodboard_list=[];
            this.room_list=[];
            this.moodboard_data=null;
            this.display_adder=0;
            }
                })
        },
        deleteProduct(product){
            this.save_form.products = this.save_form.products.filter((i)=>i[0]!=product)
        }
    }
}
</script>

<style scoped>
.boq-form{
    padding:20px;
    margin:20px;
    border: 2px dotted #00c75b;
}
.filter-title{
    font-weight: 800;
}
.product-display{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.product-comp{
    margin-top:25px;
}
.last-button{
    margin-left:40px;
}
.input-field{
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #fff;
	border-radius: 5px;
	border:1px solid #000;
	font-size: 14px;
    margin-bottom: 15px;
}
</style>

