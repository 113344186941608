<template>
    <div class="product-collection-list">
        <div class="row">
            <div>
                <div class="collection-card" v-for="collection in collection_list" :key="collection.id">
                    <div><span class="head">Name : </span><span>{{collection.name}}</span></div>
                    <div><span class="head">Theme : </span><span>{{collection.theme}}</span></div>
                    <div><p>{{collection.description}}</p></div>
                    <div><span class="head">Furnishing Products : </span><span v-for="product in collection.furnishing_products" :key="product.id">| {{product.name}} |</span></div>
                    <div><span class="head">Decor Products : </span><span v-for="product in collection.decor_products" :key="product.id">| {{product.name}} |</span></div>
                </div>

            </div>
            <div>
                <u><h1 class="filter-title">Filter By Theme</h1></u>
                <form>
                    <select @change="filterByTheme()" v-model="theme_id">
                        <option value=null disabled selected>Themes</option>
                        <option value="All">All</option>
                        <option :value="theme.id" v-for="theme in theme_data" :key="theme.id" >
                            {{theme.name}}
                        </option>
                    </select>
                </form>
            </div>
        </div>
    </div>
    
</template>

<script>
import Api from 'utils/Api'
export default {
    name:'ProductCollectionList',
    props:{
        theme_data:{
            type:Array
        }

    },
    data(){
        return{
            collection_list:[],
            theme_id:null,
            
        }

    },
    mounted(){
        Api.get('product/collection/').then(response => {
            this.collection_list = response.data.collection_data;
        }).catch(e => {
            console.log({ status: e.response.status, data: e.response.data }) 
        })
    },
    methods:{
        filterByTheme(){
            let par = this.theme_id;
            if (par=='All'){
                Api.get('product/collection/').then(response => {
            this.collection_list = response.data.collection_data;
        }).catch(e => {
            console.log({ status: e.response.status, data: e.response.data }) 
        })
            }
            else{
                Api.get('product/collection/?theme_id='+par).then(response => {
            this.collection_list = response.data.collection_data;
        }).catch(e => {
            console.log({ status: e.response.status, data: e.response.data }) 
        })
            }
        }
    }
}
</script>

<style scoped>
.product-collection-list{
    padding:20px;
    margin:20px;
    border: 2px dotted #00c75b;
}
.collection-card{
    display: flex;
    flex-direction: column;
    border:3px solid #00c75b;
    margin:10px;
    border-radius: 5px;
    padding: 15px;
}
.head{
    font-weight: bold;
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.filter-title{
    font-weight: 800;
}
</style>