<template>
    <div>
        <button v-if="type==='normal'"><img  :src="img_url" :alt="name"/></button>
        <button v-if="type==='selected'"><img class="selected_image" :src="img_url" /></button>
    </div>

</template>

<script>
export default {
    name:'ProductImageCard',
    props:{
        img_url:{
            type:String,
        },
        type:{
            type:String,
        },
        name:{
            type:String,
        }
    }
    
}
</script>

<style scoped>
img{
    height : 150px;
    width: 150px;
}
.selected_image{
    border: 4px solid #00c75b;
}

</style>