<template>
    <button class="py-1.5 px-3 font-extrabold text-white" style="background-color: #00c75b;">
        <slot>
        </slot>
    </button>
</template>
<script>
export default {
    name: 'PaginateLargeButton',
}
</script>